#modoo-day-sales-product {
  & .page-content-box {
    & .top-container {
      margin-bottom: 10px;
      & .search-container {
        display: flex;
        & ul {
          width: 100%;
          list-style: none;
          margin: 0;
          padding: 0;
          & li {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            & .title-box {
              margin-right: 10px;
              padding-bottom: 5px;
            }
          }
          & li:nth-child(1) {
            width: 70%;
            & .search-button-box {
              margin-left: 10px;
              & button {
                width: 70px;
                font-size: 16px;
                color: #333;
                line-height: 20px;
                height: 32px;
                border: 1px solid black;
                border-radius: 20px;
              }
              & button:hover {
                color: white;
                background-color: #333;
                transition: 0.5s;
              }
            }
            & .title-box {
              margin-right: 40px;
            }
            & .period {
              width: 333px;
              display: flex;
              & div {
                display: flex;
                align-items: center;
              }
              & .range-text {
                margin: 0 10px;
              }
              & .MuiFormControl-root {
                & .MuiInputBase-root {
                  width: 100%;
                  height: 32px;
                  padding-left: 13px;
                  padding-bottom: 2px;
                  & .MuiInputBase-input {
                    position: relative;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
      & .btn-container {
        display: flex;
        justify-content: end;
        & .return-main-btn {
          width: auto;
          height: 35px;
          margin-right: 10px;
          padding: 0 32px;
          border-radius: 50px;
          border: 1px #232323 solid;
          background: #232323;
          text-align: center;
          font-size: 14px;
          color: #fff;
          font-weight: 600;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }
        & .excel-btn {
          width: 180px;
          height: 35px;
          border: 1px solid #3091ff;
          border-radius: 20px;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            background-color: #3091ff;
            color: white;
            transition: 0.5s;
          }
        }
      }
    }
    & .table-container {
      & .MuiPaper-root {
        box-shadow: none !important;
        & .MuiTableContainer-root {
          & .MuiBox-root {
            box-shadow: none;
          }
          & table thead tr th.MuiTableCell-root {
            border-top: 2px #00182e solid;
            background-color: #fff;
            border-bottom: 1px solid #888888;
            box-shadow: none;
          }
          & tfoot {
            & tr {
              & td {
                padding: 0;
              }
            }
          }
        }
        & .MuiInputBase-root {
          border: 1px solid black;
          border-radius: 3px;
          background-color: white;
          padding-left: 5px;
        }
      }
    }
  }
}
