#modoo-sales {
  & .modoo-sales-wrapper {
    & th {
      line-height: 34px;
    }
    & tbody {
      & td {
        cursor: pointer;
      }
    }
  }
}
