/*
      CSS 규칙
    - div 태그 내부요소가 하나인 경우 wrapper로 명명
    - div 태그 내부요소가 여러개인 경우 container로 명명
*/

// common
@import "./fonts.scss";
@import "./contents.css";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  text-align: center;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  min-width: 1400px;
  font-family: $notoSansKr !important;
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    overflow-x: scroll;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #0070f3;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
  margin: auto;
}

.spinner-container {
  position: fixed;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  z-index: 100001;
}

.skeleton-container {
  display: flex;
  align-items: center;
  width: 1200px;
  height: 655px;
}

.LazyLoad {
  opacity: 0;
}

.is-visible {
  opacity: 1;
}

.sticky-top {
  position: sticky;
  top: 0;
}

button {
  cursor: pointer;
  border: none;
  background-color: white;
  color: black;
  font-family: "Noto Sans KR", sans-serif !important;
}

.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: inherit;
}

.MuiModal-root {
  z-index: 500000 !important;
}

#bg_layer {
  z-index: 999998 !important;
}

#nice_layer {
  z-index: 999999 !important;
}

pre {
  font-family: "Noto Sans KR", sans-serif !important;
  white-space: pre-wrap;
}

.page-container {
  min-height: 100vh;
  // 바디 container 영역
  & .body-container {
    height: calc(100% - 172px);
    width: 100%;
    min-height: 797px;
    display: flex;
    // 컨텐츠 wrapper 영역
    & .content-wrapper {
      width: calc(100% - 220px);
      padding: 24px 20px 24px 40px;
      .MuiInputBase-root {
        border-radius: 8px;
      }
      h2 {
        width: 100%;
        border-bottom: 1px #e5e5e5 solid;
        padding: 0 0 12px 0;
        margin: 0 0 20px 0;
        strong {
          font-size: 18px;
          color: #333;
          line-height: 1.2;
        }
        span {
          position: relative;
          font-size: 18px;
          color: #333;
          font-weight: normal;
          padding: 0 0 0 12px;
          margin: 0 0 0 12px;
        }
      }
      // 컨텐츠 영역
      & .page-contents {
        height: 100%;
        & .page-title {
          line-height: 1.2;
          font-size: 20px;
          text-align: left;
          padding: 0 0 10px 0;
          font-weight: 400;
          margin: 0 auto;
          & span:first-of-type {
            font-weight: 600;
          }
        }
        // 컨텐츠 박스 영역
        & .page-content-box {
          min-height: 700px;
          width: 100%;
          padding: 30px;
          border: 1px #d2d2d2 solid;
          border-radius: 15px;
        }
        // 공지사항 등록
        & .reBx {
          // 내용 title
          & .ab {
          }
          // 내용 content
          & .data {
            & .toastui-editor-defaultUI {
              // 본문내용
              .toastui-editor-main {
                // 표
                table {
                  thead {
                    tr {
                      th {
                        background-color: #747474 !important;
                        p {
                          color: #fff;
                          font-weight: 700;
                        }
                      }
                    }
                  }
                }
              }
              // 글자크기
              & .toastui-editor-popup {
                > .toastui-editor-popup-body {
                  // outline: 1px solid red;
                  > ul {
                    > li {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 32px;
                      > h2 {
                        margin: 0;
                        padding: 0;
                      }
                    }
                  }
                }
              }
              // hover 했을때 title
              & .toastui-editor-tooltip {
                padding: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.MuiInputBase-input.MuiOutlinedInput-input {
  border: none;
}

// components
@import "./components/Modal.scss";
@import "./components/Header.scss";
@import "./components/Footer.scss";
@import "./components/SideMenu.scss";
@import "./components/signOutModal.scss";

// pages
@import "./Book/Master/BookSearch.scss";
@import "./Book/Master/BookRegister.scss";
@import "./Book/Master/BookModifyRequest.scss";
@import "./Book/Master/BookRecommendedInstitutions.scss";
@import "./Book/Master/BookRecommendedCategory.scss";
@import "./Book/Master/BookRecommendedInstitutionsRegister.scss";
@import "./Book/Master/BookReviewManagement.scss";
@import "./Book/Master/BookReviewForbiddenWord.scss";
@import "./Book/Author/BookAuthorManagement.scss";
@import "./Common/Company/CommonCompanyInfo.scss";
@import "./Common/Employee/CommonEmployee.scss";
@import "./Common/Employee/CommonEmployeeRegister.scss";
@import "./Common/Employee/CommonRegisterAuthority.scss";
@import "./Common/Popup/CommonPopup.scss";
@import "./Common/Popup/CommonPopupRegister.scss";
@import "./Common/ScmPassword/CommonScmPasswordReset.scss";
@import "./Home/Board/HomeNews.scss";
@import "./Home/Board/HomeNewsRegister.scss";
@import "./Home/Board/HomeNotice.scss";
@import "./Home/Board/HomeNoticeRegister.scss";
@import "./Home/Board/HomeReferenceLibrary.scss";
@import "./Home/Board/HomeReferenceLibraryRegister.scss";
@import "./Home/Common/HomeContacts.scss";
@import "./Home/Common/HomeContactsModify.scss";
@import "./Home/Common/HomeFiles.scss";
@import "./Home/Common/HomeFilesUpload.scss";
@import "./Home/Introduction/HomeExecutivesAndEmployees.scss";
@import "./Home/Introduction/HomeExecutivesAndEmployeesRegister.scss";
@import "./Home/Introduction/HomeHistory.scss";
@import "./Home/Introduction/HomeHistoryRegister.scss";
@import "./Home/Member/HomeCooperativeMember.scss";
@import "./Home/Member/HomeCooperativeMemberRegister.scss";
@import "./Home/Member/HomeMember.scss";
@import "./Home/Member/HomeMemberRegister.scss";
@import "./Main/Main.scss";
@import "./Mall/Balance/MallSearchBalance.scss";
@import "./Mall/Community/MallChannel.scss";
@import "./Mall/Community/MallChannelRegister.scss";
@import "./Mall/Community/MallNews.scss";
@import "./Mall/Community/MallNewsRegister.scss";
@import "./Mall/Community/MallStory.scss";
@import "./Mall/Community/MallStoryRegister.scss";
@import "./Mall/Exposure/MallMain.scss";
@import "./Mall/Marketing/MallMarketing.scss";
@import "./Mall/Marketing/MallMarketingRegister.scss";
@import "./Mall/Setting/MallSetting.scss";
@import "./Mall/Member/MallMember.scss";
@import "./Mall/Member/MallMemberDetail.scss";
@import "./Mall/LifeJoin/MallLifeJoin.scss";
@import "./Mall/LifeJoin/MallLifeJoinDetail.scss";
@import "./Modoo/Events/ModooEvent.scss";
@import "./Modoo/Events/ModooEventRegister.scss";
@import "./Modoo/Events/ModooExhibition.scss";
@import "./Modoo/Events/ModooExhibitionRegister.scss";
@import "./Modoo/Exposure/ModooExposureCategory.scss";
@import "./Modoo/Exposure/ModooExposureMain.scss";
@import "./Modoo/Exposure/ModooExposureRecommend.scss";
@import "./Modoo/Inquiry/ModooFAQ.scss";
@import "./Modoo/Inquiry/ModooOneToOne.scss";
@import "./Modoo/Mall/ModooMallManagement.scss";
@import "./Modoo/Member/ModooMember.scss";
@import "./Modoo/Member/ModooMemberDetail.scss";
@import "./Modoo/Notice/ModooNotice.scss";
@import "./Modoo/Notice/ModooNoticeRegister.scss";
@import "./Modoo/Order/ModooCancelDetail.scss";
@import "./Modoo/Order/ModooCancelStatus.scss";
@import "./Modoo/Order/ModooExchangeDetail.scss";
@import "./Modoo/Order/ModooExchangeStatus.scss";
@import "./Modoo/Order/ModooOrderDetail.scss";
@import "./Modoo/Order/ModooOrderStatus.scss";
@import "./Modoo/Order/ModooReturnDetail.scss";
@import "./Modoo/Order/ModooReturnStatus.scss";
@import "./Modoo/Policy/ModooCoupon.scss";
@import "./Modoo/Policy/ModooDelivery.scss";
@import "./Modoo/Policy/ModooSaveDiscountCalculate.scss";
@import "./Modoo/Policy/ModooTerms.scss";
@import "./Modoo/Sales/ModooDaySalesAll.scss";
@import "./Modoo/Sales/ModooDaySalesOrgnz.scss";
@import "./Modoo/Sales/ModooDaySalesClient.scss";
@import "./Modoo/Sales/ModooDaySalesOrderSeq.scss";
@import "./Modoo/Sales/ModooDaySalesProduct.scss";
@import "./Modoo/Sales/ModooDaySalesSeparation.scss";
@import "./Modoo/Sales/ModooMonthFundSaving.scss";
@import "./Modoo/Sales/ModooMonthSalesAll.scss";
@import "./Modoo/Sales/ModooMonthSalesClient.scss";
@import "./Modoo/Sales/ModooMonthSalesProfit.scss";
@import "./Modoo/Sales/ModooMonthSalesSeparation.scss";
@import "./Modoo/Sales/ModooSales.scss";
@import "./Modoo/Sales/ModooMallMonthSettlement.scss";
@import "./Modoo/Sales/ModooMallDaySettlement.scss";
@import "./Pub/ManagerInfo/PubManagerInfo.scss";
@import "./Pub/Notice/PubNotice.scss";
@import "./Pub/Notice/PubNoticeRegister.scss";
@import "./Pub/PressRelease/PubPressRelease.scss";
@import "./SignIn/SignIn.scss";
@import "./Store/NewBook/StoreNewBookList.scss";
@import "./Store/NewBook/StoreNewBookListRegister.scss";
@import "./Store/Notice/StoreNotice.scss";
@import "./Store/Notice/StoreNoticeRegister.scss";
@import "./Store/OneToOne/StoreOneToOne.scss";
@import "./Store/Order/StoreSearchForwarding.scss";
@import "./Store/Order/StoreSearchOrder.scss";
@import "./Store/Return/StoreRegisterReturnIn.scss";
@import "./Store/Return/StoreSearchReturnIn.scss";
@import "./Store/Return/StoreSearchReturnOut.scss";
